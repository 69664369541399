import {
  Component,
  EnvironmentInjector,
  NgModule,
  OnInit,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { LayoutState } from '@flink-legacy/core/states/layout-state/layout.reducers';
import { Store } from '@ngrx/store';
import { Observable, Subject, filter } from 'rxjs';
import { getPageTransitionAnimated } from '@flink-legacy/core/states/layout-state/layout.selectors';
import { IonicModule } from '@ionic/angular';
import { MainMenuComponent } from '../main-menu/main-menu.component';
import { TabsNavigationComponent } from '../tabs-navigation/tabs-navigation.component';
import { DirectivesModule } from '@flink-legacy/shared/directives/directives.module';
import { MessageNotificationService } from '@flink-legacy/shared/services/message-notification.service';
import { ActionCableRepository } from '@flink-legacy/shared/repositories/action-cable.repository';

import { MessageNotificationsState } from '@flink-legacy/core/states/message-notifications-state/message-notifications.state';

@NgModule({})
export class IhzMemberUiLayoutAppLayoutComponentIconsModule {}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    MainMenuComponent,
    TabsNavigationComponent,
    DirectivesModule,
    IhzMemberUiLayoutAppLayoutComponentIconsModule,
  ],
  templateUrl: './ihz-member-ui-layout-app-layout.component.html',
  styleUrls: ['./ihz-member-ui-layout-app-layout.component.scss'],
  providers: [],
})
export class IhzMemberUiLayoutAppLayoutComponent implements OnInit {
  private router = inject(Router);
  private store = inject(Store<LayoutState>);
  public environmentInjector = inject(EnvironmentInjector);
  private ngUnsubscribe = new Subject<void>();

  withoutTabs = true;
  pageTransitionAnimated$: Observable<boolean> = this.store.select(
    getPageTransitionAnimated
  );

  // If tabs navigation needs to be hidden on specific routes,
  // enter it here.
  private readonly HIDE_ON_URLS = [
    '/daily-reservable/',
    '/daily-reservation/',
    '/edit/',
    '/group/',
    '/help',
    '/new/',
    '/hourly-reservable/',
    '/post/',
    '/privacy-policy',
    '/profile/',
    '/ticket/',
    '/event',
    '/changelog',
    '/my-profile/delete-account',
    '/invoices',
    '/membership-plan',
  ];

  constructor(
    private messageNotificationService: MessageNotificationService,
    private actionCableRepository: ActionCableRepository,
    private messageNotificationsStore: Store<MessageNotificationsState>
  ) {}

  ngOnInit() {
    this.handleHide(this.router.url);
    this.onNavigationEvent();
  }

  /**
   * Hide tabs if it matches url where tabs should be hidden.
   */
  onNavigationEvent() {
    this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.handleHide(event.url);
      });
  }

  handleHide(currentUrl: string) {
    this.withoutTabs = this.HIDE_ON_URLS.some(url => currentUrl.includes(url));
  }
}
