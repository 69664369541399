import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { BaseSubscriberAbstract } from '@flink-legacy/core/base-subscriber.abstract';
import { AfterViewInit, Directive, Injector, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class InputAbstract
  extends BaseSubscriberAbstract
  implements AfterViewInit, OnInit, ControlValueAccessor
{
  public control = new FormControl();

  // for cases when we need all value changes, even the ones ignored by `emitEvent: false`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected allFormValueChanges = new ReplaySubject<any>(1);

  constructor(protected injector: Injector) {
    super();
  }

  /**
   * custom form control (ControlValueAccessor) implementation
   */

  // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
  private onChange: (_: any) => void;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private onTouched: () => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(val: any): void {
    this.control.patchValue(val, { emitEvent: false });
    this.allFormValueChanges.next(val);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // calling control.disable() / control.enable() triggers valueChanges
    // we want it only if the disabled state really changed
    if (isDisabled !== this.control.disabled) {
      if (isDisabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  ngOnInit() {
    // sync this.control to outside form control
    this.control.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(val => {
        this.onChange(val);
        this.onTouched();
      });
  }

  ngAfterViewInit(): void {
    // hack – can't sync outside form control validators to this.control
    // so after each state change sync the errors so the ionic-select display proper errors
    try {
      const ngControl = this.injector.get<NgControl>(NgControl);
      if (ngControl.control) {
        ngControl.control.statusChanges
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(_ => {
            this.control.setErrors(ngControl.errors);
          });
      }
    } catch {
      // eslint-disable-next-line no-console
      console.warn(
        `No [formControl], formControlName or [(ngModel)] defined for ${this.constructor.name}`
      );
    }
  }
}
