import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { UserShort } from '@flink-legacy/core/declarations/user.interface';
import { IonicModule } from '@ionic/angular';

export type AvatarUser = Pick<
  UserShort,
  'first_name' | 'last_name' | 'avatar_url' | 'id'
>;

let uniqueId = 0;
@Component({
  selector: 'fl-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonicModule, NgIf],
})
export class AvatarComponent implements OnInit {
  @Input() user: AvatarUser;
  @Input() clickable = true;
  @Input() size: string | number = 64;

  avatarText: string | null = null;
  public uniqueId = uniqueId++;

  constructor(private router: Router, private cdref: ChangeDetectorRef) {}

  ngOnInit() {
    this.buildTextAvatar();
  }

  showProfile(id: number): void {
    this.router.navigateByUrl(`/profile/${id}`);
  }

  private buildTextAvatar(): void {
    this.avatarText = this.constructInitials([
      this.user?.first_name,
      this.user?.last_name,
    ]);
    this.cdref.markForCheck();
  }

  /**
   * Iterates a person's name string to get the initials of each word in uppercase.
   */
  private constructInitials(elements: string[]): string {
    if (!elements || !elements.length) {
      return '';
    }

    return elements
      .filter(element => element && element.length > 0)
      .map(element => element[0].toUpperCase())
      .join('');
  }
}
