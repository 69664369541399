import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getCurrentTenantApiUrl } from '@flink-legacy/core/states/tenant-state/tenant.selectors';
import { TenantState } from '@flink-legacy/core/states/tenant-state/tenant.state';
import { Device } from '@capacitor/device';
import { Store } from '@ngrx/store';

export interface Device {
  id?: number;
  model: string;
  uuid: string;
  token: string;
  platform: string;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceRegisterService {
  apiUrl: string;
  constructor(
    private http: HttpClient,
    private tenantStore: Store<TenantState>
  ) {
    tenantStore
      .select(getCurrentTenantApiUrl)
      .pipe()
      .subscribe(url => {
        this.apiUrl = url;
      });
  }

  async store(token: string) {
    const info = await Device.getInfo();
    const { uuid } = await Device.getId();
    const body = {
      model: `${info.manufacturer} ${info.model}`,
      uuid,
      token,
      platform: info.platform,
    };

    try {
      await this.http.post<Device>(`${this.apiUrl}/devices`, body).toPromise();
    } catch (e) {
      // Don't rethrow, but silence the error
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  async deactivate() {
    try {
      const { uuid } = await Device.getId();
      await this.http
        .delete<Device>(`${this.apiUrl}/devices/${uuid}`)
        .toPromise();
    } catch (e) {
      // Don't rethrow, but silence the error
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}
