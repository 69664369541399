import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CustomModalOptions,
  ModalAbstractComponent,
} from '@bling-fe/shared/modals/base-modal';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UiModalHeaderComponent } from '@bling-fe/ihz-member/ui-components/ui-modal-header';

interface Item {
  title: string;
  icon: string;
  url: string;
}

@Component({
  selector: 'ih-new-modal',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    IonicModule,
    TranslateModule,
    RouterModule,
    UiModalHeaderComponent,
  ],
  templateUrl: './new-modal.component.html',
  styleUrls: ['./new-modal.component.scss'],
  providers: [
    {
      provide: ModalAbstractComponent,
      useExisting: NewModalComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewModalComponent extends ModalAbstractComponent<never, boolean> {
  public static override modalOptions: CustomModalOptions = {
    cssClass: 'auto-height auto-width bottom-sheet-modal ihz-new-modal',
  };

  items: Item[] = [
    {
      title: _('IHZ_MEMBER.UI_NEW_MODAL.NEWS_POST'),
      icon: 'note-pencil',
      url: '/new/post/article',
    },
    {
      title: _('IHZ_MEMBER.UI_NEW_MODAL.OFFER'),
      icon: 'shopping-cart-simple',
      url: '/new/post/marketplace',
    },
    {
      title: _('IHZ_MEMBER.UI_NEW_MODAL.SURVEY'),
      icon: 'chart-bar-square',
      url: '/new/poll',
    },
    {
      title: _('IHZ_MEMBER.UI_NEW_MODAL.CHANNEL'),
      icon: 'wifi-antenna',
      url: '/group/new',
    },
    {
      title: _('IHZ_MEMBER.UI_NEW_MODAL.EVENT'),
      icon: 'calendar',
      url: '/new/event',
    },
    {
      title: _('IHZ_MEMBER.UI_NEW_MODAL.CHAT'),
      icon: 'users',
      url: '/community',
    },
    {
      title: _('IHZ_MEMBER.UI_NEW_MODAL.SPACE_REQUEST'),
      icon: 'reception-bell',
      url: '/services/tickets/new/space_request',
    },
    {
      title: _('IHZ_MEMBER.UI_NEW_MODAL.SPECIFIC_REQUEST'),
      icon: 'reception-bell',
      url: '/services/tickets/new/specific_request',
    },
  ];
}
