<ion-avatar
  [style.height]="size + 'px'"
  [style.width]="size + 'px'"
  (click)="user && clickable && showProfile(user?.id)"
  [class.clickable]="user && clickable"
  [class.clickable]="clickable"
>
  <img
    *ngIf="user?.avatar_url; else nameIntialsAvatar"
    [src]="user?.avatar_url"
    alt=""
  />
  <ng-template #nameIntialsAvatar>
    <svg
      viewBox="0 0 1 1"
      *ngIf="avatarText"
    >
      <mask [id]="'avatarsRadiusMask' + uniqueId">
        <rect
          width="1"
          height="1"
          rx="0"
          ry="0"
          x="0"
          y="0"
          fill="#fff"
        />
      </mask>
      <g [attr.mask]="'url(#avatarsRadiusMask' + uniqueId + ')'">
        <rect
          width="1"
          height="1"
          fill="#F5F6F8"
        />
        <text
          x="50%"
          y="50%"
          style="font: 600 0.4px Inter, Arial, sans-serif"
          fill="#88889C"
          text-anchor="middle"
          dy="0.15"
        >
          {{ avatarText }}
        </text>
      </g>
    </svg>
  </ng-template>
</ion-avatar>
