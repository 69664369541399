<ion-item
  lines="none"
  *ngIf="loggedUser$ | async as user"
>
  <fl-avatar
    [user]="user"
    [size]="40"
    [clickable]="false"
    class="mr-2"
  ></fl-avatar>
  <ng-container *ngIf="!isEditing; else editingComment">
    <ion-textarea
      [placeholder]="'COMMON.LABELS.WRITE_COMMENT' | translate"
      [(ngModel)]="comment"
      (ngModelChange)="commentChanged($event)"
      [disabled]="disableEditingComment"
      (keyup.enter)="checkSubmit()"
      auto-grow="true"
      rows="1"
      maxlength="1000"
    ></ion-textarea>
    <ion-button
      (click)="submit()"
      [disabled]="disableSubmitBtn"
      shape="round"
    >
      {{ "COMMON.LABELS.POST" | translate }}
    </ion-button>
  </ng-container>
  <ng-template #editingComment>
    <div class="flex-1">
      <ion-textarea
        class="editing-comment-input"
        [placeholder]="'COMMON.LABELS.WRITE_COMMENT' | translate"
        [(ngModel)]="comment"
        (ngModelChange)="commentChanged($event)"
        [disabled]="disableEditingComment"
        (keyup.enter)="checkSubmit()"
        auto-grow="true"
        rows="4"
        maxlength="1000"
      ></ion-textarea>
      <div class="flex--end mt-2">
        <ion-button
          class="cancel-btn"
          (click)="cancel()"
          shape="round"
          fill="outline"
        >
          {{ "COMMON.LABELS.CANCEL" | translate }}
        </ion-button>
        <ion-button
          (click)="submit()"
          [disabled]="disableSubmitBtn"
          shape="round"
        >
          {{ "COMMON.LABELS.UPDATE" | translate }}
        </ion-button>
      </div>
    </div>
  </ng-template>
</ion-item>
