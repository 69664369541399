import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { User } from '@flink-legacy/core/declarations/user.interface';
export interface UserState {
  currentUser: User;
}

export const initialState: UserState = {
  currentUser: null,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.setCurrentUser, (state, user) => ({
    ...state,
    currentUser: user,
  })),
  on(UserActions.logout, state => ({
    ...state,
    currentUser: null,
  }))
);
